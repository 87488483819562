import React from 'react'
import { PageProps } from 'gatsby'
import { Head, Breadcrumb, Footer } from '~/components'
import * as st from '~/assets/styl/PrivacyPolicy.module.styl'

interface Context {
  name: string
  title: string
  text: string
}

const PrivacyPolicy = ({
  location,
  pageContext,
}: PageProps & { pageContext: Context }) => (
  <>
    <Head
      location={location}
      title={pageContext.name + ' - ' + process.env.GATSBY_SITE_NAME}
    />
    <header className={st.header}>
      <Breadcrumb />
      <h1>{pageContext.title}</h1>
    </header>
    <div className={st.content}>
      <p dangerouslySetInnerHTML={{ __html: pageContext.text }}></p>
    </div>
    <Footer />
  </>
)

export default PrivacyPolicy
